;
(function (window, $) {
    'use strict';

    var TOOLTIP_CLASSNAME = 'yui-tooltip',
        NAMEPSACE = 'yuitooltip',
        POSITION = {
            TOP: 'top',
            BOTTOM: 'bottom',
            LEFT: 'left',
            RIGHT: 'right'
        },
        EVENT = {
            HOVER: 'mouseenter.yuitooltip mouseleave.yuitooltip',
            FOCUS: 'focus.yuitooltip blur.yuitooltip focusin.yuitooltip focusout.yuitooltip'
        },
        THEME = {
            WHITE: '',
            DARK: TOOLTIP_CLASSNAME + '--dark'
        };

    var YuiTooltip = function (element, options) {
        this.init(element, options);
    };

    YuiTooltip.prototype = {
        init: function (element, options) {
            this.$obj = $(element);
            this.options = $.extend({
                delay: 0,
                animSpeed: 200,
                autoHide: true,
                theme: THEME.WHITE,
                gap: 10,
                position: POSITION.TOP,
                event: EVENT.HOVER
            }, options, {
                position: $(element).data('position') ? $(element).data('position') : POSITION.TOP
            });
            this.$body = $('body');
            this._initEvents();
        },
        _initEvents: function () {
            var $this = this;
            this.$obj.on(this.options.event, function (event) {
                switch (event.type) {
                    case 'focus':
                    case 'mouseenter':
                        $this._delay = setInterval(function () {
                            $this.show();
                            clearInterval($this._delay);
                        }, $this.options.delay);
                        break;
                    case 'blur':
                    case 'mouseleave':
                        clearInterval($this._delay);
                        if (typeof $this._tooltip !== 'undefined') {
                            $this.hide();
                        }
                        break;
                }
            });
        },
        show: function () {
            var tooltipWidth,
                elementWidth,
                diff,
                top,
                left;

            this._initContainerLayer();

            if (typeof this.$obj.data('title') !== 'undefined' && this._isExist) {
                this._tooltip.html(this.$obj.data('title'));
            } else if (typeof this.$obj.attr('data-title') !== 'undefined' && this._isExist) {
                this._tooltip.html(this.$obj.attr('data-title'));
            }

            tooltipWidth = this._tooltip.innerWidth();
            elementWidth = this.$obj.innerWidth();
            diff = Math.floor((tooltipWidth / 2) - (elementWidth / 2));

            switch (this.options.position) {
                case POSITION.TOP:
                    top = Math.floor(this.$obj.offset().top - (this._tooltip.innerHeight() + this.options.gap));
                    left = Math.floor(this.$obj.offset().left - diff);
                    break;
                case POSITION.BOTTOM:
                    top = Math.floor(this.$obj.offset().top + this.$obj.innerHeight() + this.options.gap);
                    left = Math.floor(this.$obj.offset().left - diff);
                    break;
                case POSITION.LEFT:
                    top = Math.floor(this.$obj.offset().top - ((this._tooltip.innerHeight() - this.$obj.innerHeight()) / 2));
                    left = Math.floor(this.$obj.offset().left - this._tooltip.innerWidth() - 10);
                    break;
                case POSITION.RIGHT:
                    top = Math.floor(this.$obj.offset().top - ((this._tooltip.innerHeight() - this.$obj.innerHeight()) / 2));
                    left = Math.floor(this.$obj.offset().left + this.$obj.innerWidth() + 10);
                    break;
            }
            this._tooltip.css({
                'left': left,
                'top': top
            });


            if (typeof this.$obj.data('title') !== 'undefined' ||
                typeof this.$obj.attr("data-title") !== 'undefined' ||
                (!this._isExist && typeof this._tooltip !== 'undefined')) {
                switch (this.options.position) {
                    case POSITION.TOP:
                        this._tooltip.addClass("yui-tooltip--show-slideup");
                        break;
                    case POSITION.BOTTOM:
                        this._tooltip.addClass("yui-tooltip--show-slidedown");
                        break;
                    case POSITION.LEFT:
                        this._tooltip.addClass("yui-tooltip--show-slideleft");
                        break;
                    case POSITION.RIGHT:
                        this._tooltip.addClass("yui-tooltip--show-slideright");
                        break;
                }
            }
        },

        hide: function () {
            var $this = this;
            this._tooltip.stop().animate({'opacity': 0}, $this.options.animSpeed, function () {
                $(this).hide();
                if ($this._isExist) {
                    $this._destroyTooltip();
                }
            });
        },

        _destroyTooltip: function () {
            this._tooltip.remove();
            this._isExist = false;
            delete this._tooltip;
        },

        _initContainerLayer: function () {
            var $this = this;

            if (typeof $this.$obj.data('target') !== 'undefined') {
                $this._tooltip = $($this.$obj.data('target'));
            } else if (typeof $this.$obj.attr('data-target') !== 'undefined') {
                $this._tooltip = $($this.$obj.attr('data-target'));
            }

            if (typeof $this._tooltip === 'undefined' && !$this._isExist) {
                var tooltip = $('<div>')
                    .addClass(TOOLTIP_CLASSNAME + ' ' + TOOLTIP_CLASSNAME + '--' + $this.options.position)
                    .addClass(this.options.theme);
                this.$body.append(tooltip);
                this._tooltip = tooltip;
                this._isExist = true;
            }
            if (!this.options.autoHide) {
                this._tooltip.on('mouseenter.' + NAMEPSACE + ' mouseleave.' + NAMEPSACE, function (event) {
                    switch (event.type) {
                        case 'mouseenter':
                            $(this).stop().animate({
                                "opacity": 1
                            }, $this.options.animSpeed);
                            break;
                        case 'mouseleave':
                            $(this).stop().animate({"opacity": 0}, $this.options.animSpeed, function () {
                                $(this).hide();
                                if ($this._isExist) {
                                    $this._destroyTooltip();
                                }
                            });
                            break;
                    }
                });
            }
        }
    };

    $.extend(YuiTooltip, {
        POSITION: POSITION,
        EVENT: EVENT,
        THEME: THEME
    });

    var old = $.fn.yuitooltip;

    $.fn.yuitooltip = function (options) {
        var args = options;
        return this.each(function () {
            var isInit = $(this).data(NAMEPSACE);
            if (!isInit) {
                $(this).data(NAMEPSACE, new YuiTooltip(this, args));
            }
        });
    };
    window.YuiTooltip = YuiTooltip;
    $.fn.yuitooltip.Constructor = YuiTooltip;

    $.fn.yuitooltip.noConflict = function () {
        $.fn.yuitooltip = old;
        return this;
    };
})(window, window.jQuery);