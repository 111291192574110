;(function ($, document) {
    'use strict';
    var NAMESPACE = 'yuialert',
        CONTAINER_CLASS_NAME = 'yui-alert_container',
        STYLE = {
            SLIDE_TOP: {
                TEMPLATE: '<div class="yui-alert-slidetop">' +
                    '<div class="yui-alert-slidetop_inner">' +
                    '<span class="yui-alert-slidetop_icon"></span>' +
                    '<div class="yui-alert-slidetop_text"></div>' +
                    '</div>' +
                    '<span class="yui-alert_close yui-icon-del"></span>' +
                    '</div>',
                SUCCESS: {
                    CLASS: 'yui-alert-slidetop--success',
                    ICON: 'yui-icon-check-circle'
                },
                ERROR: {
                    CLASS: 'yui-alert-slidetop--error',
                    ICON: 'yui-icon-alert'
                },
                SHOW: 'yui-alert-slidetop--show',
                HIDE: 'yui-alert-slidetop--hide'
            }
        },
        EFFECT = {
            SLIDE_ON_TOP: 1,
            THUMB_SLIDER: 2
        },
        TYPE = {
            ERROR: 1,
            SUCCESS: 2
        };
    var YuiAlert = function (options) {
        this._options = $.extend({
            message: '',
            thumb: '',
            effect: EFFECT.SLIDE_ON_TOP,
            type: TYPE.SUCCESS,
            ttl: 6000,
            onOpen: function () {
                return false;
            },
            onClose: function () {
                return false;
            }
        }, options);
        this._init();
    };
    $.extend(YuiAlert, {
        EFFECT: EFFECT,
        TYPE: TYPE
    });
    YuiAlert.prototype = {
        _init: function () {
            this.$_alert = $(STYLE.SLIDE_TOP.TEMPLATE);
            this._icon = $('.yui-alert-slidetop_icon', this.$_alert);
            this._closeBtn = $('.yui-alert_close', this.$_alert);
            switch (this._options.effect) {
                /* SLIDE TOP */
                case EFFECT.SLIDE_ON_TOP:
                    switch (this._options.type) {
                        case TYPE.ERROR:
                            this.$_alert.addClass(STYLE.SLIDE_TOP.ERROR.CLASS);
                            this._icon.addClass(STYLE.SLIDE_TOP.ERROR.ICON);
                            break;
                        case TYPE.SUCCESS:
                            this.$_alert.addClass(STYLE.SLIDE_TOP.SUCCESS.CLASS);
                            this._icon.addClass(STYLE.SLIDE_TOP.SUCCESS.ICON);
                            break;
                    }
                    $('.yui-alert-slidetop_text', this.$_alert).html(this._options.message);
                    break;
            }
            this._isActive = false;
        },
        _initCloseEvents: function () {
            var self = this;
            this._closeBtn.bind('click.' + NAMESPACE, function () {
                self.hide();
            });
        },
        _initContainer: function () {
            this.$_container = $('<div>').addClass(CONTAINER_CLASS_NAME);
            this.$_container.insertBefore(document.body.firstChild);
        },
        _destroy: function () {
            var self = this,
                iId = setInterval(function () {
                    self.$_alert.remove();
                    self._reset();
                    clearTimeout(iId);
                }, 300);
            this._isActive = false;
        },
        _reset: function () {
            switch (this._options.effect) {
                /* SLIDE TOP */
                case EFFECT.SLIDE_ON_TOP:
                    this.$_alert.removeClass(STYLE.SLIDE_TOP.HIDE).removeClass(STYLE.SLIDE_TOP.SHOW);
                    break;
            }
            this._isActive = false;
        },
        show: function () {
            var self = this;
            this.$_container = $('.' + CONTAINER_CLASS_NAME);
            if (this.$_container.length === 0) {
                this._initContainer();
            }
            if (!this._isActive) {
                this._isActive = true;
                switch (this._options.effect) {
                    /* SLIDE TOP */
                    case EFFECT.SLIDE_ON_TOP:
                        this.$_alert.addClass(STYLE.SLIDE_TOP.SHOW);
                        break;
                }
                this.$_container.append(this.$_alert);
                this._intervalId = setTimeout(function () {
                    self.hide();
                }, this._options.ttl);
            }
            this._initCloseEvents();
        },
        hide: function () {
            clearTimeout(this._intervalId);
            switch (this._options.effect) {
                /* SLIDE TOP */
                case EFFECT.SLIDE_ON_TOP:
                    this.$_alert
                        .removeClass(STYLE.SLIDE_TOP.SHOW)
                        .addClass(STYLE.SLIDE_TOP.HIDE);
                    break;
            }
            this._destroy();
        }
    };

    window.YuiAlert = YuiAlert;
})(window.jQuery, document);