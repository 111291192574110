;
(function ($) {
    'use strict';
    var NAMESPACE = 'yuiselect',
        SELECT_CLASS = 'yui-select',
        SELECT_OPEN_CLASS = 'yui-select--open',
        SELECT_COVER_CLASS = 'yui-select--cover',
        SELECT_MULTIPLE_CLASS = 'yui-select--multiple',
        ELEMENT_CLASS = 'yui-select_element',
        TRIGGER_CLASS = 'yui-select_trigger',
        TRIGGER_TEXT_CLASS = 'yui-select_trigger-text',
        ICON_CONTAINER_CLASS = 'yui-select_icon-container',
        OPTION_WRAPPER_CLASS = 'yui-select_list-wrapper',
        OPTION_LIST_CLASS = 'yui-select_list',
        OPTION_LIST_SCROLL_CLASS = 'yui-select_list--scroll',
        OPTION_CLASS = 'yui-select_item',
        OPTION_SELECTED_CLASS = 'yui-select_item--selected',
        OPTION_DISABLED_CLASS = 'yui-select_item--disabled',
        OPTION_BOLD_CLASS = 'yui-select_item--bold',
        OPTION_GROUP_CLASS = 'yui-select_group',
        SCROLLER_BAR_CLASS = 'yui-select_scroller-bar',
        SCROLLER_CLASS = 'yui-select_scroller';

    var YuiSelect = function (element, config) {
        var self = this;
        self.element = $(element);
        self.config = config;
        self.optionListScroll = 0;
        self.multiple = false;
        this._checkConf = function () {
            if (typeof self.config.showEffect !== 'function') {
                self.config.showEffect = 'fadeIn';
            }
            if (typeof self.config.hideEffect !== 'function') {
                self.config.hideEffect = 'fadeOut';
            }
            if (typeof self.element.attr('multiple')!==typeof undefined && self.element.attr('multiple')!== false) {
                self.multiple = true;
            }
        };

        this.init = function () {
            if (self.element.data('yuiselect')) return;
            self._checkConf();
            self._insertReplacer();
            self._copyOriginalEvents();
            if (self.multiple) {
                self.replacer.addClass(SELECT_MULTIPLE_CLASS);
                if (self.optionWrapper.outerHeight(true) < self.optionList[0].scrollHeight) {
                    self.scrollerWrapper.css({
                        'z-index': '4'
                    });
                }
                self._setEventsMultiple();
            }
            else {
                self._setEvents();
            }
            self.element.data('yuiselect', 1);

        };
        this._copyOriginalEvents = function () {
            // Копируем события оригинального селекта
            var elemEvents = $._data(self.element.get(0), 'events');
            if (elemEvents) {
                $.each(elemEvents, function () {
                    $.each(this, function () {
                        if (this.type != 'change'){
                            self.replacer.on(this.type, this.handler);
                        }
                    });
                });
            }
        };
        this._setEvents = function () {

            self.trigger.on("click." + NAMESPACE, function () {
                self.listFunctions._toggleList();
            });

            self.option.on("click." + NAMESPACE, function () {
                if (!$(this).hasClass(OPTION_DISABLED_CLASS)) {
                    self.element.val($(this).attr('data-val'));

                    var change = (!self.selectedOption || self.selectedOption.attr('data-val') !== $(this).attr('data-val'));

                    self.listFunctions._hideList();
                    if (change) {
                        self.element.trigger('change');
                    }
                }
            });
            self.element.on('change.' + NAMESPACE, function(){
                var currentOption = self.optionList.find('*[data-val="'+ self.element.val() +'"]');
                if (currentOption.length < 1) return false;

                if (self.selectedOption) {
                    self.selectedOption.removeClass(OPTION_SELECTED_CLASS);
                }
                self.selectedOption = currentOption;
                self.selectedOption.addClass(OPTION_SELECTED_CLASS);

                self.triggerText.text(currentOption.first().text());
            });
        };
        this._setEventsMultiple = function () {
            self._defineScrollTop();
            self._initScroll();
            self._setScrollerEvents();

            self.option.on('click.' + NAMESPACE, function(){
                if (!$(this).hasClass(OPTION_DISABLED_CLASS)) {
                    var elemValue = self.element.val() || [];
                    var optionValue = $(this).attr('data-val');
                    if ($(this).hasClass(OPTION_SELECTED_CLASS)) {
                        var delIndex = $.inArray(optionValue, elemValue);
                        elemValue.splice(delIndex, 1);
                    }
                    else {
                        elemValue.push(optionValue);
                    }
                    self.element.val(elemValue);
                    self.element.trigger('change');
                }
            });
            self.element.on('change.' + NAMESPACE, function(){
                var val = self.element.val();

                self.option.removeClass(OPTION_SELECTED_CLASS);
                console.log(val);
                if (val == null) return;
                for(var i = 0; i < val.length; i++){
                    self.optionList.find('*[data-val="'+ val[i] +'"]').addClass(OPTION_SELECTED_CLASS);
                }
            });
        };
        this._closeWhenClickOutside = function () {
            $(document).on("mouseup." + NAMESPACE, function (e) {
                if (!self.replacer.is(e.target) && // if the target of the click isn't the container...
                    self.replacer.has(e.target).length === 0) // ... nor a descendant of the container
                {
                    self.listFunctions._hideList();
                    $(document).off('mouseup.' + NAMESPACE);
                }
            });
        };
        this._defineScrollTop = function () {
            // IE не сохранял позицию скроллера optionList, поэтому делаем принудительное присваивание
            self.optionList[0].scrollTop = self.optionListScroll;

            if (!self.selectedOption) return false;

            var selectedOptionTop = self.selectedOption.position().top,
                selectedOptionHeight = self.selectedOption.outerHeight(true),
                optionListHeight = self.optionList.outerHeight(true),
                optionListScrollTop = self.optionList[0].scrollTop;

            if (selectedOptionTop > optionListHeight) {
                self.optionList[0].scrollTop = selectedOptionTop + selectedOptionHeight / 2 - optionListHeight / 2;
            }
            else if (selectedOptionTop < 0) {
                self.optionList[0].scrollTop = optionListScrollTop + selectedOptionTop;
            }
            else if (optionListHeight - selectedOptionTop < selectedOptionHeight) {
                self.optionList[0].scrollTop = optionListScrollTop + selectedOptionHeight - (optionListHeight - selectedOptionTop);
            }
        };

        this._initScroll = function () {
            self.scrollerData = {
                enable: true,
                optionListHeight: self.optionList.outerHeight(true),
                optionListScrollTop: self.optionList[0].scrollTop,
                scrollerBarHeight: self.scrollerWrapper.outerHeight(true),
                optionListScrollHeight: self.optionList[0].scrollHeight,
                scrollerHeight: self.config.scrollerHeight
            };
            if (self.scrollerData.optionListScrollHeight <= self.scrollerData.optionListHeight) {
                self.scrollerData.enable = false;
                return;
            }

            self.optionList.addClass(OPTION_LIST_SCROLL_CLASS);

            self.scrollerData.scrollerPosTopMax = self.scrollerData.scrollerBarHeight - self.scrollerData.scrollerHeight;
            self.scrollerData.optionListScrollTopMax = self.scrollerData.optionListScrollHeight - self.scrollerData.optionListHeight;

            self.scrollerData.scrollerTop = self.optionList[0].scrollTop * (self.scrollerData.scrollerPosTopMax / self.scrollerData.optionListScrollTopMax);

            self.scroller.css({
                'height': self.scrollerData.scrollerHeight,
                'top': self.scrollerData.scrollerTop
            });
        };

        this._setScrollerEvents = function () {
            if (!self.scrollerData.enable) {
                return;
            }
            var scrollMove = function () {
                self._initScroll();
                self.scroller.css({
                    'top': self.scrollerData.scrollerTop
                });
            };
            self.optionList.on('scroll.' + NAMESPACE, scrollMove);

            self.scroller.on('mousedown.' + NAMESPACE + ' touchstart.' + NAMESPACE, function (event) {
                event.preventDefault();
                self.scrollerData.scrollerWrapperOffsetTop = self.scrollerWrapper.offset().top;
                var scrollerTop = 0;
                if (event.type == 'mousedown') {
                    scrollerTop = self.scroller.position().top - (event.pageY - self.scrollerData.scrollerWrapperOffsetTop);
                }
                else if (event.type == 'touchstart') {
                    scrollerTop = self.scroller.position().top - (event.originalEvent.touches[0].pageY - self.scrollerData.scrollerWrapperOffsetTop);
                }

                self.optionList.off('scroll.' + NAMESPACE);
                $(document.body).on('selectstart.' + NAMESPACE, false);
                $(document).on('mouseup.scroller' + NAMESPACE + ' touchend.' + NAMESPACE, function () {
                    $(document.body).off('mousemove.' + NAMESPACE + ' selectstart.' + NAMESPACE + ' touchmove.' + NAMESPACE);
                    $(window).off('mousedown.' + NAMESPACE);
                    $(document).off('mouseup.scroller' + NAMESPACE);
                    self.optionList.on('scroll.' + NAMESPACE, scrollMove);
                });

                $(window).on('mousedown.' + NAMESPACE, function (e) {
                    e.preventDefault();
                });
                $(document.body).on('mousemove.' + NAMESPACE + ' touchmove.' + NAMESPACE, function (e) {
                    var scrollerPosTop = 0;
                    if (e.type == 'mousemove') {
                        scrollerPosTop = scrollerTop + e.pageY - self.scrollerData.scrollerWrapperOffsetTop;
                    }
                    else if (e.type == 'touchmove') {
                        scrollerPosTop = scrollerTop + e.originalEvent.touches[0].pageY - self.scrollerData.scrollerWrapperOffsetTop;
                    }
                    if (scrollerPosTop > self.scrollerData.scrollerPosTopMax) {
                        scrollerPosTop = self.scrollerData.scrollerPosTopMax;
                    }
                    else if (scrollerPosTop < 0) {
                        scrollerPosTop = 0;
                    }
                    self.scroller.css({
                        'top': scrollerPosTop
                    });
                    self.optionList[0].scrollTop = self.scroller.position().top * self.scrollerData.optionListScrollTopMax / self.scrollerData.scrollerPosTopMax;
                });
            });

        };
        this._destroyScrollerEvents = function () {
            self.optionList.off('DOMMouseScroll.' + NAMESPACE + ' ' + 'mousewheel.' + NAMESPACE);
            self.scroller.off('mousedown.' + NAMESPACE + ' touchstart.' + NAMESPACE);
        };
        this.listFunctions = {
            _toggleList: function () {
                if (self.optionWrapper.css('display') == 'none') {
                    self.listFunctions._showList();
                }
                else
                    self.listFunctions._hideList();
            },
            _showList: function () {
                if (!self.config.cover) {
                    self.replacer.addClass(SELECT_COVER_CLASS);
                }
                self.optionWrapper[self.config.showEffect](self.config.showEffectSpeed, function () {
                    self._defineScrollTop();
                    self._initScroll();
                    self._setScrollerEvents();
                    self._closeWhenClickOutside();
                });

                self.replacer.addClass(SELECT_OPEN_CLASS);
            },
            _hideList: function () {
                // IE сохраняем позицию скроллера перед закрытием
                self.optionListScroll = self.optionList[0].scrollTop;
                self.optionWrapper[self.config.hideEffect](self.config.hideEffectSpeed, function () {
                    self.replacer.removeClass(SELECT_OPEN_CLASS + ' ' + SELECT_COVER_CLASS);
                    self._destroyScrollerEvents();
                });
            }
        };

        self._insertReplacer = function () {
            var optionList = [],
                $options = self.element.find('option, optgroup'),
                currentOption = {
                    'text': self.element.find('option:selected').text()
                };
            if (self.multiple) {
                currentOption.val = [];
            }
            else {
                currentOption.val = self.element[0].value;
            }

            self.replacer = $('<div>').attr({
                'id': 'yui-' + self.element[0].id,
                'style': self.element.attr('style'),
                'class': self.element.attr('class'),
                'tabindex': self.element[0].tabindex || 0
            });
            self.replacer.addClass(SELECT_CLASS);

            $options.each(function () {
                if ($(this).prop('tagName') == 'OPTGROUP') {
                    optionList.push({
                        'tag': 'OPTGROUP',
                        'text': $(this).attr('label')
                    });
                }
                else if ($(this).prop('tagName') == 'OPTION') {
                    optionList.push({
                        'tag': 'OPTION',
                        'text': $(this).html(),
                        // если отсутствует атрибут value, берем текстовое значение option
                        'dataVal': $(this).attr('value') || this.innerHTML,
                        'isBold': $(this).data('is-bold') || false,
                        'disabled' : false
                    });
                    var disabled = $(this).attr('disabled');
                    if (typeof disabled !== typeof undefined && disabled!== false) {
                        optionList[optionList.length-1].disabled = true;
                    }
                    var selected = $(this).attr('selected');
                    if (self.multiple && typeof selected !== typeof undefined && selected !== false) {
                        currentOption.val.push(this.value);
                    }
                }
            });

            self.trigger = $('<span>').addClass(TRIGGER_CLASS);
            self.triggerText = $('<span>').addClass(TRIGGER_TEXT_CLASS).text(currentOption.text);
            self.iconContainer = $('<span>').addClass(ICON_CONTAINER_CLASS);
            self.icon = $('<i>').addClass(self.config.triggerIconClass);
            self.optionWrapper = $('<div>', {
                'class': OPTION_WRAPPER_CLASS
            });
            self.optionList = $('<div>').addClass(OPTION_LIST_CLASS);
            self.scrollerWrapper = $('<div>', {
                'class': SCROLLER_BAR_CLASS
            });
            self.scroller = $('<div>', {
                'class': SCROLLER_CLASS
            })
                .appendTo(self.scrollerWrapper);

            if (!self.config.cover) self.optionWrapper.css({top: 'auto'});

            var elemParent = self.element.parent();

            self.element.addClass(ELEMENT_CLASS);//.prependTo(self.replacer);
            self.trigger.appendTo(self.replacer);
            self.triggerText.appendTo(self.trigger);
            self.iconContainer.appendTo(self.trigger);
            self.icon.appendTo(self.iconContainer);
            self.optionList.appendTo(self.optionWrapper);

            for (var i = 0; i < optionList.length; i++) {
                if (optionList[i].tag == 'OPTGROUP') {
                    $('<span>')
                        .attr('class', OPTION_GROUP_CLASS)
                        .text(optionList[i].text)
                        .appendTo(self.optionList);
                }
                else if (optionList[i].tag == 'OPTION') {
                    var $listItem = $('<span>');
                    $listItem
                        .attr('class', OPTION_CLASS)
                        .text(optionList[i].text)
                        .attr('data-val', optionList[i].dataVal)
                        .appendTo(self.optionList);
                    if (optionList[i].dataVal == currentOption.val || (typeof currentOption.val  == 'object' && $.inArray(optionList[i].dataVal, currentOption.val) > -1)) {
                        self.selectedOption = $listItem;
                        self.selectedOption.addClass(OPTION_SELECTED_CLASS);
                    }
                    if (optionList[i].disabled) {
                        $listItem.addClass(OPTION_DISABLED_CLASS);
                    }
                    if (optionList[i].isBold) {
                        $listItem.addClass(OPTION_BOLD_CLASS);
                    }
                }
            }
            self.scrollerWrapper.appendTo(self.optionWrapper);
            self.optionWrapper.appendTo(self.replacer);
            self.option = self.optionList.find('.' + OPTION_CLASS);

            self.replacer.appendTo(elemParent);
        };
    };
    $.fn.yuiselect = function (config) {
        if (YuiDetector.isOperaMini()) return;
        var cfg = $.extend(true, {}, $.fn.yuiselect.defaultConfig, config);
        return this.each(function () {
            if ($(this).prop('tagName') === 'SELECT') {
                var target = new YuiSelect(this, cfg);
                target.init();
                $(this).data(NAMESPACE, target);
            }
        });
    };
    $.fn.yuiselect.defaultConfig = {
        'showEffect': 'slideDown',
        'showEffectSpeed': 0,
        'hideEffect': 'slideUp',
        'hideEffectSpeed': 0,
        'triggerIconClass': 'yui-icon-arrow-down',
        'cover': true,
        'scrollerHeight': 50
    };
})(jQuery);