/**
 * $(el).yuimodal();
 */
;
(function ($) {
    'use strict';
    var NAMESPACE = 'yuimodal',
        OPACITY_ANIMATION_SPEED = 300, //ms
        MODAL = {
            className: 'yui-modal',
            showClassName: 'yui-modal--show',
            LAYER: {
                BG: {
                    className: 'yui-modal-layer-bg'
                },
                CLOSE: {
                    className: 'yui-modal_layer-close'
                },
                SCROLL: {
                    className: 'yui-modal_layer-scroll',
                    fixedClassName: 'yui-modal_layer-scroll--fixed'
                },
                INNER: {
                    className: 'yui-modal_inner',
                    hideClassName: 'yui-modal_inner--hide',
                    showClassName: 'yui-modal_inner--show',
                    fixedClassName: 'yui-modal_inner--fixed'
                },
                LOADER: {
                    showClassName: 'yui-modal_loader--show',
                    className: 'yui-modal_loader'
                }
            },
            BODY: {
                className: 'yui-modal_body'
            },
            CLOSE: {
                className: 'yui-modal_close'
            }
        };
    var YuiModal = function (element, options) {
        this.$obj = $(element);
        this._isShown = false;
        this.options = $.extend({
            backdrop: true,
            autocentered: true,
            link: $(),
            onShow: function () {
            },
            /**
             @param {Object} Объект контейнера модалки.
             @param {Object} Объект на котором произошло событие (клик)
             @return {*}
             */
            beforeShow: function () {
            },
            onClose: function () {
                return true;
            }
        }, options);
        this._init();
    };
    YuiModal.prototype = {
        resizeTimeout: null,
        _init: function () {
            this.$body = $('body');
            this.$userBlock = this.$obj.clone(true);
            this._initBodyContainerLayer();
            this._initEvents();
        },
        _initEvents: function () {
            var self = this;
            this.$userBlock.on('show.' + NAMESPACE, function () {
                self.show();
            }).on('hide.' + NAMESPACE, function () {
                self.hide();
            }).on('showLoader.' + NAMESPACE, function () {
                self.showLoader();
            }).on('hideLoader.' + NAMESPACE, function () {
                self.hideLoader();
            });
            this._closeBtn.on('click.' + NAMESPACE, function () {
                self.$userBlock.trigger('hide.' + NAMESPACE);
            });
            this.options.link.on('click.' + NAMESPACE, function () {
                self.$userBlock.trigger('show.' + NAMESPACE);
                return false;
            });
            this.$userBlock.resize();
        },
        _initResizeEvents: function () {
            var self = this;
            if (this.options.autocentered) {
                this.$userBlock.on('resize.' + NAMESPACE, function () {
                    if (self._isShown) {
                        self.center();
                    }
                });
                $(window).on('resize.' + NAMESPACE, function () {
                    if (self._isShown) {
                        self.center();
                    }
                });
            }
        },
        _destroyResizeEvents: function () {
            this.$userBlock.off('resize.' + NAMESPACE);
            $(window).off('resize.' + NAMESPACE);
        },
        _setBodyScrollable: function (isScrollable) {
            if (isScrollable) {
                this.$body.removeClass('noscroll');
                $(document).unbind('touchmove');
            } else {
                this.$body.addClass('noscroll');
                $(document).bind('touchmove', false);
            }
        },
        _getCloseLayer: function () {
            var self = this;
            this._$closeLayer = $('<div>').addClass(MODAL.LAYER.CLOSE.className);
            this._$closeLayer.on('click.' + NAMESPACE, function () {
                self.hide();
            });
            return this._$closeLayer;
        },
        _initBgLayer: function () {
            this._$layerBg = $('<div>').addClass(MODAL.LAYER.BG.className);
            this.$body.prepend(this._$layerBg);
            this._$layerBg.css({
                opacity: 0
            }).animate({
                opacity: 0.6
            }, 100);
        },
        _destroyBgLayer: function () {
            this._$layerBg.animate({
                opacity: 0
            }, 100, function () {
                $(this).remove();
            });
        },
        showLoader: function () {
            this._$loader.addClass(MODAL.LAYER.LOADER.showClassName);
        },
        hideLoader: function () {
            this._$loader.removeClass(MODAL.LAYER.LOADER.showClassName);
        },
        show: function (func) {
            var self = this;
            if (func) {
                this.options.beforeShow = func;
            }
            this.options.beforeShow.call(this, this.$userBlock, this.options.link);
            if (!this._isShown) {
                this._initBgLayer();
                this._setBodyScrollable(false);
                self._showBodyContainerLayer();
                self.options.onShow.call(self, self.$userBlock, self.options.link);
                self._isShown = true;
            }
        },
        hide: function () {
            if (this.options.onClose.call(this, this.$userBlock, this.options.link)) {
                this._destroyBgLayer();
                this._hideBodyContainerLayer();
                this._setBodyScrollable(true);
                this._isShown = false;
            }
        },
        _initBodyContainerLayer: function () {
            this._$containerWrap = $('<div>')
                .addClass(MODAL.className);

            this._$scrollerContainer = $('<div>').addClass(MODAL.LAYER.SCROLL.className);

            this._$container = $('<div>')
                .addClass(MODAL.LAYER.INNER.className);

            this._$containerBody = $('<div>')
                .addClass(MODAL.BODY.className)
                .append(this.$userBlock);

            this._$container.append(this._$containerBody);
            this._$container.append(this._getCloseBtn());
            if (this.options.backdrop) {
                this._$scrollerContainer.append(this._getCloseLayer());
            }
            this._$container.append(this._getLoaderHtml());
            this._$scrollerContainer.append(this._$container);
            this._$containerWrap.append(this._$scrollerContainer);
            this.$obj.replaceWith(this._$containerWrap);
        },
        _startShowAnimation: function () {
            this._$container
                .addClass(MODAL.LAYER.INNER.showClassName);
        },
        _startHideAnimation: function () {
            var dfd = new $.Deferred();
            this._$container
                .removeClass(MODAL.LAYER.INNER.showClassName)
                .addClass(MODAL.LAYER.INNER.hideClassName);
            setTimeout(function () {
                dfd.resolve();
            }, OPACITY_ANIMATION_SPEED);
            return dfd.promise();
        },
        _showBodyContainerLayer: function () {
            var self = this;
            this.$userBlock.data('attrDisplay', this.$userBlock.css('display'));
            this._$containerWrap.addClass(MODAL.showClassName);
            this._$container.removeClass(MODAL.LAYER.INNER.hideClassName);
            this.$userBlock.show();
            $.when(this.center())
                .then(function () {
                    self._startShowAnimation();
                });
            this._initResizeEvents();
        },
        _hideBodyContainerLayer: function () {
            var self = this;
            $.when(this._startHideAnimation())
                .then(function () {
                    self.$userBlock.css({
                        display: self.$userBlock.data('attrDisplay')
                    });
                    self._$containerWrap.removeClass(MODAL.showClassName);
                });
            this._destroyResizeEvents();
        },
        _center: function () {
            var dfd = new $.Deferred();
            if ($(window).height() > this._$container.outerHeight()) {
                this._$scrollerContainer.addClass(MODAL.LAYER.SCROLL.fixedClassName);
                this._$container
                    .addClass(MODAL.LAYER.INNER.fixedClassName)
                    .animate({
                        top: Math.floor(($(window).height() / 2) - (this._$container.outerHeight() / 2)) + 'px',
                        left: Math.floor(($(window).width() / 2) - (this._$container.outerWidth() / 2)) + 'px'
                    }, 100, function () {
                        dfd.resolve();
                    });
            } else {
                this._$scrollerContainer.removeClass(MODAL.LAYER.SCROLL.fixedClassName);
                this._$container
                    .removeClass(MODAL.LAYER.INNER.fixedClassName)
                    .css({
                        top: '',
                        left: ''
                    });
                dfd.resolve();
            }
            return dfd.promise();
        },
        center: function () {
            return this._center();
        },
        _getLoaderHtml: function () {
            this._$loader = $('<div>').addClass(MODAL.LAYER.LOADER.className);
            return this._$loader;
        },
        _getCloseBtn: function () {
            if (this._closeBtn) {
                $(this._closeBtn).remove();
            }
            this._closeBtn = $('<span>').attr({
                'class': MODAL.CLOSE.className + ' yui-icon-del'
            });
            return this._closeBtn;
        }
    };

    /**
     * $.fn.dsyuimodal - Использовать его, если используется старая глобалка. Иначе конфликт с другой глобалкой
     * @type {yuimodal}
     */

    $.fn.dsyuimodal = $.fn.yuimodal = function (method) {
        if (typeof method === 'string') {
            var args = Array.prototype.slice.call(arguments, 1);
            return this.each(function () {
                // проверка инициализации
                var target = $(this).data(NAMESPACE);
                if (!target) {
                    target = new YuiModal(this, args[0]);
                    $(this).data(NAMESPACE, target);
                }
                target.$userBlock.trigger(method + '.' + NAMESPACE);
            });
        } else if (typeof method === 'object' || !method) {
            return this.each(function () {
                // проверка инициализации
                var target = $(this).data(NAMESPACE);
                if (!target) {
                    $(this).data(NAMESPACE, new YuiModal(this, method));
                }
            });
        } else {
            $.error('Не могу инициализировать YuiModal');
        }
    };

    $.fn.dsyuimodal.Constructor = $.fn.yuimodal.Constructor = YuiModal;
})(window.jQuery);