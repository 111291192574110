;(function (window, document, $) {
    'use strict';
    var NAMESPACE = 'yuitab',
        DROPDOWN_ELEMENT_WIDTH = 37,
        utils = {
            getNamespacedEvents: function (events) {
                var arr = events.split(' ');
                for (var i = 0; i < arr.length; i++) {
                    arr[i] = arr[i] + '.' + NAMESPACE;
                }
                return arr.join(' ');
            }
        };

    var TAB = {
        className: 'yui-tab',
        ITEMS: {
            className: 'yui-nav_item',
            activeClassName: 'yui-nav_item--active',
            CONTAINER: {
                className: 'yui-nav'
            },
            INNER: {
                className: 'yui-nav_item_inner'
            }
        },
        DROPDOWN: {
            className: 'yui-nav--tab-dropdown',
            TOGGLE: {
                className: 'yui-nav_item-toggle',
                INNER: {
                    className: 'yui-nav_item-toggle_inner'
                }
            }
        },
        CONTENTS: {
            className: 'yui-tab_content',
            PANE: {
                className: 'yui-tab_pane'
            }
        }
    };

    var YuiTab = function (element, options) {
        this.$obj = $(element);
        this._options = $.extend({
            callback: function ($object, $element) {
            },
            onClick: function ($element) {
            },
            saveState: false,
            cookie: {
                paramName: 'yiutabindex',
                expires: 1, //days,
                path: '/'
            }
        }, options);
        this._init(element, options);
    };

    YuiTab.prototype = {
        _init: function () {
            this.$navContainer = $('.' + TAB.ITEMS.CONTAINER.className, this.$obj);
            this.$tabs = $('.' + TAB.ITEMS.className, this.$navContainer);
            this.$contents = $('.' + TAB.CONTENTS.className, this.$obj);
            this.$panes = $('.' + TAB.CONTENTS.PANE.className, this.$contents);
            this.tabsWidthSum = 0;
            this._allTabs = [];
            var self = this;
            this.$tabs.each(function (ind, el) {
                self.tabsWidthSum += $(el).outerWidth();
                self._allTabs.push({
                    'element': $(el),
                    'width': $(el).outerWidth()
                });
            });
            this._initEvents();
            this._initActiveTab();
            this._initDropdown();
            this._recountNav();
        },
        _initEvents: function () {
            var self = this;
            this.$obj.on('click.' + NAMESPACE, '.' + TAB.ITEMS.className, function () {
                self.$curTab = $(this);

                var isActive = self.$curTab.hasClass(TAB.ITEMS.activeClassName);
                if (isActive) {
                    var redirectUrl = $('.' + TAB.ITEMS.INNER.className, self.$curTab).data('redirect');
                    if (redirectUrl) {
                        window.location.href = redirectUrl;
                    }
                }

                self.$tabs.removeClass(TAB.ITEMS.activeClassName);
                self.$dropdownNav.find('.' + TAB.ITEMS.className).removeClass(TAB.ITEMS.activeClassName);
                self.$curTab.addClass(TAB.ITEMS.activeClassName);
                self._showTabContent();
                self._saveTabState();
                self._options.onClick.call(this, self.$curTab);
                $('.' + TAB.DROPDOWN.className, self.$obj).hide();
            });
            this.$obj
                .on('click.' + NAMESPACE, '.' + TAB.DROPDOWN.TOGGLE.INNER.className, function () {
                    $('.' + TAB.DROPDOWN.className, self.$obj).toggle();
                })
                .find('.' + TAB.ITEMS.CONTAINER.className)
                .bind('clickoutside.' + NAMESPACE, function () {
                    $('.' + TAB.DROPDOWN.className, self.$obj).hide();
                });
            $(window).on('resize.' + NAMESPACE, function () {
                self._recountNav();
            });
        },
        _recountNav: function () {
            this.navContainerWidth = this.$navContainer.outerWidth();
            this._visibleCount = 0;
            this.$tabs.show();
            if (this.navContainerWidth < this.tabsWidthSum) {
                this._showDropdown();
                var tempTabsWidthSum = DROPDOWN_ELEMENT_WIDTH, _i;
                for (_i = 0; _i < this._allTabs.length; _i++) {
                    var tabObject = this._allTabs[_i];
                    tempTabsWidthSum += parseInt(tabObject.width);
                    if (this.navContainerWidth >= tempTabsWidthSum) {
                        this._visibleCount++;
                    } else {
                        break;
                    }
                }
                for (_i = this._visibleCount; _i < this._allTabs.length; _i++) {
                    this._allTabs[_i].element.hide();
                }
                this._recountDropdown();
            } else {
                this.$tabs.last().css({
                    'border-radius': '0 4px 4px 0'
                });
                this._hideDropdown();
            }
        },
        _showDropdown: function () {
            this.$dropdownToggle.show();
        },
        _hideDropdown: function () {
            this.$dropdownToggle.hide();
        },
        _initDropdown: function () {
            this.$dropdownNav = $('<ul>').addClass('yui-nav ' + TAB.DROPDOWN.className);
            this.$dropdownToggle = $('<li>').addClass(TAB.DROPDOWN.TOGGLE.className);
            var toggleInner = $('<span>').addClass(TAB.DROPDOWN.TOGGLE.INNER.className),
                toggleIcon = $('<i>').addClass('yui-icon-chevron-down'),
                itemsFragment = $(document.createDocumentFragment());
            for (var _i = 0; _i < this._allTabs.length; _i++) {
                itemsFragment.append(this._allTabs[_i].element.clone(true));
            }
            this.$dropdownNav.append(itemsFragment);
            toggleInner.append(toggleIcon);
            this.$dropdownToggle.append(toggleInner)
                .append(this.$dropdownNav);
            this.$navContainer.append(this.$dropdownToggle);
        },
        _recountDropdown: function () {
            this.$dropdownNav.find('.' + TAB.ITEMS.className).show();
            for (var _i = 0; _i < this._visibleCount; _i++) {
                this.$dropdownNav.find('.' + TAB.ITEMS.className + ':eq(' + _i + ')').hide();
            }
        },
        _initActiveTab: function () {
            var $this = this,
                cookieTabIndex = $.cookie(this._options.cookie.paramName);
            this.$tabs.each(function (ind, el) {
                if ($(el).hasClass(TAB.ITEMS.activeClassName)) {
                    $this.$curTab = $(el);
                }
            });
            if (typeof cookieTabIndex === 'string' && this._options.saveState) {
                this.$curTab = this.$tabs.eq(parseInt(cookieTabIndex));
            }
            this.$tabs.removeClass(TAB.ITEMS.activeClassName);
            this.$curTab.addClass(TAB.ITEMS.activeClassName);
            this._showTabContent();
        },
        _showTabContent: function () {
            var paneId = $('.' + TAB.ITEMS.INNER.className, this.$curTab).data('paneId');
            this.$panes.hide();
            $(paneId).show();
            this._options.callback.call(this, this.$obj, this.$curTab);
        },
        _saveTabState: function () {
            if (this._options.saveState) {
                $.cookie(this._options.cookie.paramName, this.$curTab.index(), this._options.cookie);
            }
        }
    };

    $.fn.yuitab = function (options) {
        var target;
        return this.each(function () {
            target = $(this).data(NAMESPACE);
            if (!target) {
                $(this).data(NAMESPACE, new YuiTab(this, options));
            }
        });
    };
    $.fn.yuitab.Constructor = YuiTab;
})(window, document, jQuery);