(function(d) {
    var wf = d.createElement('script'), s = d.scripts[0];
    wf.src = 'https://ajax.googleapis.com/ajax/libs/webfont/1/webfont.js';
    wf.onload = function () {
        "use strict";
        WebFont.load({
            custom: {
                families: ['DINRoundPro', 'flaticon']
            }
        });
    };
    s.parentNode.insertBefore(wf, s);
})(document);