;(function ($) {
    var NAMESPACE = 'yuicheckbox';
    var YuiCheckbox = function (element, options) {
        this.$element = $(element);
        this.$label = null;
        this.$wrapper = null;
        this.$led = null;
        this.options = $.extend({
            //default options
        }, options);
        this.init();
    };
    YuiCheckbox.prototype = {
        init: function () {
            this.$led = $('<span>', {
                'class': 'yui-checkbox_led'
            });
            this.$wrapper = $('<div>', {
                'class': 'yui-checkbox'
            });
            this.$label = this.$element.next();

            this.addClassNames();
            this.addWrapper();
            this.appendLed();
        },
        addWrapper: function () {
            this.$element.next().andSelf().wrapAll(this.$wrapper);
        },
        addClassNames: function () {
            this.$element.addClass('yui-checkbox_input');
            this.$label.addClass('yui-checkbox_label');
        },
        appendLed: function () {
            this.$element.after(this.$led);
        }
    };

    $.fn.yuicheckbox = function (config) {
        if (YuiDetector.isOperaMini()) return;
        return this.each(function () {
            var isInit = $(this).data(NAMESPACE);
            if (!isInit) {
                $(this).data(NAMESPACE, new YuiCheckbox(this, config));
            }
        });
    };
    $.fn.yuicheckbox.Constructor = YuiCheckbox;
})(jQuery);