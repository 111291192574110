;(function ($) {
    "use strict";
    var NAMESPACE = 'yuiinput';
    var ERROR_CLASSNAME = 'yui-input-control--error';
    var ERROR_CONTAINER_CLASSNAME = 'yui-input-container--icon-error';
    var SUCCESS_CLASSNAME = 'yui-input-control--success';
    var SUCCESS_CONTAINER_CLASSNAME = 'yui-input-container--icon-success';
    var YuiInput = function (element) {
        this.$obj = $(element);
        this._init();
    };
    YuiInput.prototype = {
        _init: function () {
            this._initEvents();
        },
        _initEvents: function () {
            this.$obj.on('keydown.' + NAMESPACE, function () {
                $(this).removeClass(ERROR_CLASSNAME).removeClass(SUCCESS_CLASSNAME);
                $(this).parent().removeClass(ERROR_CONTAINER_CLASSNAME).removeClass(SUCCESS_CONTAINER_CLASSNAME);
            });
        }
    };
    $.fn.yuiinput = function (method) {
        if (YuiDetector.isOperaMini()) return;
        return this.each(function () {
            var target = $(this).data(NAMESPACE);
            if (typeof target === 'undefined') {
                $(this).data(NAMESPACE, new YuiInput(this, method));
            }
        });
    };
    $.fn.yuiinput.Constructor = YuiInput;
})(window.jQuery);