;(function ($) {
    var NAMESPACE = 'yuiradio';
    var YuiRadio = function (element, options) {
        this.$element = $(element);
        this.$label = null;
        this.$wrapper = null;
        this.$led = null;
        this.options = $.extend({
            //default options
        }, options);
        this.init();
    };
    YuiRadio.prototype = {
        init: function () {
            this.$led = $('<span>', {
                'class': 'yui-radio_led'
            });
            this.$wrapper = $('<div>', {
                'class': 'yui-radio'
            });
            this.$label = this.$element.next();

            this.addClassNames();
            this.addWrapper();
            this.appendLed();
        },
        addWrapper: function () {
            this.$element.next().andSelf().wrapAll(this.$wrapper);
        },
        addClassNames: function () {
            this.$element.addClass('yui-radio_input');
            this.$label.addClass('yui-radio_label');
        },
        appendLed: function () {
            this.$element.after(this.$led);
        }
    };

    $.fn.yuiradio = function (config) {
        if (YuiDetector.isOperaMini()) return;
        return this.each(function () {
            var isInit = $(this).data(NAMESPACE);
            if (!isInit) {
                $(this).data(NAMESPACE, new YuiRadio(this, config));
            }
        });
    };
    $.fn.yuiradio.Constructor = YuiRadio;
})(jQuery);