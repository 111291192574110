;(function ($) {
    'use strict';

    $.fn.yuiform = function () {
        var INPUT_CLASS = 'yui-input-control';
        return this.each(function () {
            var allChildren = $(this).find('input, select, textarea, button');
            allChildren.each(function () {
                var elem = $(this);
                var tag = elem.prop('tagName');
                var type = elem.attr('type');
                if (tag == 'INPUT') {
                    if (type == 'text') {
                        elem.addClass(INPUT_CLASS);
                    }
                    else if (type == 'checkbox') {
                        elem.yuicheckbox();
                    }
                    else if (type == 'radio') {
                        elem.yuiradio();
                    }
                }
                else if (tag == 'SELECT') {
                    elem.yuiselect();
                }
                else if (tag == 'TEXTAREA') {
                    elem.addClass(INPUT_CLASS);
                }
                else if (tag == 'BUTTON') {
                    if (type == 'submit' || type == 'reset')
                        elem.addClass('yui-btn');
                }
            });
        });
    };
})(jQuery);