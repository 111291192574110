;(function ($) {
    'use strict';
    var POPOVER_CLASS = 'yui-popover',
        POPOVER_DIRECTION = {
            bottom: 'yui-popover--bottom',
            top: 'yui-popover--top',
            left: 'yui-popover--left',
            right: 'yui-popover--right'
        },
        NAMESPACE = 'yuipopover',
        EVENT = {
            HOVER: 'mouseenter.yuipopover mouseleave.yuipopover',
            FOCUS: 'focus.yuipopover blur.yuipopover focusin.yuipopover focusout.yuipopover',
            CLICK: 'click.yuipopover'
        },
        DIRECTION = {
            TOP: 'top',
            LEFT: 'left',
            BOTTOM: 'bottom',
            RIGHT: 'right'
        };


    var YuiPopover = function (element, config) {
        this.element = $(element);
        this.elementStyle = this.element.attr('style') || '';
        this.config = $.extend({
            link: null,
            direction: DIRECTION.BOTTOM,
            duration: 300,
            event: EVENT.CLICK
        }, config);
        this.position = {
            top: 0,
            left: 0
        };
        this.arrowWidth = 10;
        this.init();
    };
    YuiPopover.prototype = {
        init: function () {
            this.setEvents();
        },
        setEvents: function () {
            var self = this;
            if (this.config.link !== null) {
                this.config.link.on(this.config.event, function (event) {
                    switch (event.type) {
                        case 'focus':
                        case 'mouseenter':
                            self.show();
                            break;
                        case 'blur':
                        case 'mouseleave':
                            self.hide();
                            break;
                        case 'click':
                            event.preventDefault();
                            self.toggle();
                            break;
                    }
                });
            }
        },
            toggle: function () {
                if (this.isShown) {
                    this.hide();
                } else {
                    this.show();
                }
            },
            show: function () {
                var self = this;
                if (typeof this.element.data(NAMESPACE) === 'undefined' || !this.element.data(NAMESPACE).hasOwnProperty('wrapper')) {
                    this.setWrapper();
                }
                this.element.data(NAMESPACE, {'status': 'show', 'wrapper': 1});
                this.wrapper = this.element.parent();
                this.element.css({'display': 'inline-block'});
                this.calculatePosition();
                this.wrapper.removeAttr('class')
                    .addClass(POPOVER_CLASS + ' ' + POPOVER_DIRECTION[self.config.direction]);
                this.wrapper.css({
                    'top': this.position.top,
                    'left': this.position.left,
                    'display': 'inline-block'
                });
                if (this.wrapper.css('opacity') === 1) {
                    this.wrapper.css({'opacity': '0'});
                }
                this.wrapper.stop(true).fadeTo(this.config.duration, 1, function () {
                    self.isShown = true;
                });
            },
            hide: function () {
                var self = this;
                this.element.data(NAMESPACE, {'status': 'hide', 'wrapper': 1});
                this.wrapper.stop(true).fadeTo(this.config.duration, 0, function () {
                    self.wrapper.css({'display': ''});
                    self.element.attr('style', self.elementStyle)
                        .unwrap()
                        .removeData(NAMESPACE);
                });
                this.isShown = false;
            },
        setWrapper: function () {
            this.wrap = $('<div>', {
                'class': POPOVER_CLASS + ' ' + POPOVER_DIRECTION[this.config.direction]
            });
            this.element.wrap(this.wrap);
        },
        calculatePosition: function () {
            var link = this.config.link;

            switch (this.config.direction) {
                case DIRECTION.BOTTOM:
                    this.position.top = link.offset().top + link.outerHeight(true) + this.arrowWidth;
                    this.position.left = link.offset().left + parseInt(link.outerWidth(true) / 2) - parseInt(this.wrapper.outerWidth(true) / 2);
                    break;
                case DIRECTION.TOP:
                    this.position.top = link.offset().top - this.wrapper.outerHeight(true) - this.arrowWidth;
                    this.position.left = link.offset().left + parseInt(link.outerWidth(true) / 2) - parseInt(this.wrapper.outerWidth(true) / 2);
                    break;
                case DIRECTION.LEFT:
                    this.position.top = link.offset().top + parseInt(link.outerHeight(true) / 2) - parseInt(this.wrapper.outerHeight(true) / 2);
                    this.position.left = link.offset().left - this.wrapper.outerWidth(true) - this.arrowWidth;
                    break;
                case DIRECTION.RIGHT:
                    this.position.top = link.offset().top + parseInt(link.outerHeight(true) / 2) - parseInt(this.wrapper.outerHeight(true) / 2);
                    this.position.left = link.offset().left + link.outerWidth(true) + this.arrowWidth;
                    break;
            }
        }
    };

    $.extend(YuiPopover, {
        EVENT: EVENT
    });

    $.fn.yuipopover = $.fn.yuidropdown = function (config) {
        return this.each(function () {
            new YuiPopover(this, config);
        });
    };
    window.YuiDropdown = window.YuiPopover = YuiPopover;
    $.fn.yuidropdown.Constructor = $.fn.yuipopover.Constructor = YuiPopover;
})(jQuery);